export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE'
}

export const userSignUpConstants = {
  USER_SIGNUP_REQUEST: 'USER_SIGNUP_REQUEST',
  USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
  USER_SIGNUP_FAILURE: 'USER_SIGNUP_FAILURE'
}

export const getCategoryConstants = {
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

  ADD_CATEGORIES_REQUEST: 'ADD_CATEGORIES_REQUEST',
  ADD_CATEGORIES_SUCCESS: 'ADD_CATEGORIES_SUCCESS',
  ADD_CATEGORIES_FAILURE: 'ADD_CATEGORIES_FAILURE'
}

export const projectConstants = {
  GET_PROJECTS_REQUEST: 'GET_USER_PROJECTS_REQUEST',
  GET_PROJECTS_SUCCESS: 'GET_USER_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_USER_PROJECTS_FAILURE',

  ADD_PROJECT_REQUEST: 'ADD_PROJECT_REQUEST',
  ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
  ADD_PROJECT_FAILURE: 'ADD_PROJECT_FAILURE',

  UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',

  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE',

  GET_PROJECT_DETAILS_BY_SEARCH_REQUEST:
    'GET_PROJECT_DETAILS_BY_SEARCH_REQUEST',
  GET_PROJECT_DETAILS_BY_SEARCH_SUCCESS:
    'GET_PROJECT_DETAILS_BY_SEARCH_SUCCESS',
  GET_PROJECT_DETAILS_BY_SEARCH_FAILURE:
    'GET_PROJECT_DETAILS_BY_SEARCH_FAILURE',

  GET_PROJECT_DETAILS_BY_ID_REQUEST: ' GET_PROJECT_DETAILS_BY_ID_REQUEST',
  GET_PROJECT_DETAILS_BY_ID_SUCCESS: ' GET_PROJECT_DETAILS_BY_ID_SUCCESS',
  GET_PROJECT_DETAILS_BY_ID_FAILURE: ' GET_PROJECT_DETAILS_BY_ID_FAILURE'
}
